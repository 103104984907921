import React from 'react';
import { NavLink } from 'react-router-dom';
import { MDBBtn } from "mdbreact";
import { getRoutes } from '../data/routes';

export const Tabs = () => {
    var routes = getRoutes();
    var tabs = routes.map((route, index) => {
        return (
            <NavLink key={index} to={route.url}>
                <MDBBtn color='deep-orange' className='radius-50' outline>
                    <b className="clear-text-white">{route.name}</b>
                </MDBBtn>
            </NavLink>
        )
    });

    return <div className="collapse navbar-collapse">
        <div className="navbar-nav mr-auto mt-lg-0">{tabs}</div>
    </div>;

}
