import React, { Component } from 'react';
import bg1 from '../assets/img/bg/bgs1.png';
import bg2 from '../assets/img/bg/bgs2.png';
import bg3 from '../assets/img/bg/bgs3.png';


export default class SectionHome extends Component {

    getImage(id) {
        if (id === 1) return `url(${bg1})`;
        if (id === 2) return `url(${bg2})`;
        return `url(${bg3})`;
    }

    render() {
        const { children, id } = this.props;
        var style = {
            backgroundImage: this.getImage(id),
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        };

        var containerClass = `container-fluid ${id === 2 ? "deep-blue lighten-5" : ""}`;

        return <div className={containerClass} style={style}>
            <div className="row p-0">
                <div className="col-md-10 offset-md-1  pt-5 pb-5">
                    <div className="container-fluid">
                        <div className="row mt-5 mb-5 align-items-center">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}