import React, { Component } from 'react';
import SectionHeader from './SectionHeader';

import SectionView from './SectionView';
import { RuleContent, RuleName, RulesWrapper } from '../components/Rules';

export default class SectionRules extends Component {
    render() {
        var { data } = this.props;

        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <RulesWrapper>
                    <RulesList rules={data.rules} />
                    <ComplexRulesList complexrules={data.complexrules} />
                </RulesWrapper>
            </SectionView>
        );
    }
}

const RulesList = ({ rules }) => {
    return rules.map((item, index) => {
        return (
            <tr key={index} className="clear-text-white">
                <RuleName>{item.title}</RuleName>
                <RuleContent>{item.content}</RuleContent>
            </tr>
        )
    });
}

const ComplexRulesList = ({ complexrules }) => {
    return complexrules.map((item, index) => <ComplexRules key={index} rules={item} />);
}

const ComplexRules = ({ rules }) => {
    var topics = rules.topics.map((item, index) => {
        return (
            <p key={index}>
                <b key={index} className="font-weight-bold">{item.text}</b>: {item.content}
                <br />
            </p>
        )
    });

    return (
        <tr className="clear-text-white">
            <RuleName>{rules.title}</RuleName>
            <RuleContent>{rules.content}<br /><br />{topics}</RuleContent>
        </tr>
    )
}
