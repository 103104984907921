import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import SectionView from "./SectionView";
import SectionAnimated from './SectionAnimated';
import { Space } from '../components/Space';

export default class SectionVideos extends Component {
    render() {
        const { data } = this.props;

        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <SectionAnimated>
                    <VideosList videos={data.videos} />
                </SectionAnimated>
                <Space />
            </SectionView>
        )
    }
}

const VideosList = ({ videos }) => {
    return videos.map((item, index) => <Video key={index} title={item.title} src={item.src} />);
}

const Video = ({ title, src }) => {
    return (
        <div className="col-md-6 col-12 text-center mt-4">
            <div className="embed-responsive embed-responsive-16by9 z-depth-2 float-right" style={{ maxWidth: '90%', }}>
                <iframe
                    allowFullScreen="allowFullScreen"
                    title={title}
                    src={src}
                    allowtransparency="true"
                    frameBorder="0">
                </iframe>
            </div>
        </div>
    )
}

