import React, { Component } from 'react';
import { MDBBtn } from 'mdbreact';
import HackFaesaDynamicText from './HackFaesaDynamicText';

export default class HackFaesaDescription extends Component {

    render() {
        var { content } = this.props;
        var animatedClass = "open-sans animated fadeInUp";

        return (
            <div className="col-md-6 padding-top-bottom more-weight text-blue" >
                <HackFaesaDynamicText />
                <p className={animatedClass}>{content.title}</p>
                <p className={animatedClass}>{content.content}</p>
                <p className={animatedClass}> {content.date}</p>
                <div className={animatedClass}>
                    <SocialMediaButtons links={content.socialMedia} />
                </div>
            </div>
        )
    }
}

const SocialMediaButtons = ({ links }) => {
    return links.map((item, index) => {
        return <MDBBtn
            key={index} href={item.link} style={{ margin: '10px' }}
            className="radius-50" color='deep-orange'
            target="_blank" rel="noopener noreferrer">
            {item.name}
        </MDBBtn>
    })
}