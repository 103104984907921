import React, { Component } from 'react';
import SectionAnimated from "../sections/SectionAnimated";


export default class SponsorsEdition extends Component {
    render() {
        var { edition } = this.props;
        return (
            <div className="row">
                <SectionAnimated>
                    <SponsorsEditionTitle title={edition.title} content={edition.content} />
                </SectionAnimated>
                <SectionAnimated>
                    <SponsorsList sponsors={edition.sponsors} />
                </SectionAnimated>
                <hr />
            </div>
        )
    }
}

const SponsorsEditionTitle = ({ title }) => <h4 className="text-orange more-weight col-md-8">{title}</h4>

const SponsorsList = ({ sponsors }) => {
    return sponsors.map((img, index) => <Sponsor key={index} img={img} />);
}

const Sponsor = ({ img }) => {
    return (
        <div className="col-md-3 col-6 text-center mt-4 margin-left-right">
            <img alt="" src={img} className='rounded-circle box-shad load-img' width="100%" height="70%" />
            <br /><br /><h4> &nbsp;</h4><br /><br />
        </div>
    )
}