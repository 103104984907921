import React, { Component } from 'react';

export default class Promoting extends Component {
    render() {
        var { promoting, numbers } = this.props;
        return (
            <>
                <div className="col-md-6 order-md-1 order-1 ">
                    <PromotingTitle title={promoting.title} />
                    <br />
                    <PromotingDescription content={promoting.content} />
                </div>
                <PromotingNumbers numbers={numbers} />
            </>
        )
    }
}

const PromotingTitle = ({ title }) => {
    return (
        <h2 className="display-5 h1-responsive animated fadeInUp text-orange more-weight">
            <b>{title}</b>
        </h2>
    )
}

const PromotingDescription = ({ content }) => {
    return <p className="open-sans text-blue more-weight">{content}</p>
}

const PromotingNumbers = ({ numbers }) => {
    var statistics = numbers.map((item, index) => {
        return <div key={index} className="col-md-4 col item-numbers">
            <h1 className="animated fadeInUp text-white">
                <b><span>{item.number}</span></b>
            </h1>
            <p className="open-sans text-white">{item.content}</p>
        </div>
    });

    return (
        <div className="col-md-6 order-md-2 order-1 pb-4">
            <div className="container-fluid">
                <div className="row">{statistics}</div>
            </div>
        </div>
    )
}