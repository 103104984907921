import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBModal, MDBModalHeader, MDBModalBody } from "mdbreact";


export default class ButtonSubscription extends Component {
    state = {
        isVisible: false
    }

    onPressButton() {
        this.setState((state) => {
            return { isVisible: !state.isVisible }
        });
    }

    render() {
        const { data } = this.props;

        return (
            <ul className="navbar-nav ml-auto nav-flex-icons">
                <li className="nav-item">
                    <MDBBtn color='deep-orange' className='radius-50' onClick={() => this.onPressButton()}>
                        Inscrições
                    </MDBBtn>
                    <MDBContainer >
                        <MDBModal isOpen={this.state.isVisible} toggle={() => this.onPressButton()} centered>
                            <MDBModalHeader toggle={() => this.onPressButton()} className='deep-orange clear-text-white more-weight' >
                                Inscrições
                            </MDBModalHeader>
                            <MDBModalBody className="margin-left-right deep-orange  clear-text-white">
                                {
                                    data.isAvailable ? <ButtonsList buttons={data.buttons} /> : <DefaultMessage />
                                }
                            </MDBModalBody>
                        </MDBModal>
                    </MDBContainer>
                </li >
            </ul>
        )
    }
}

const DefaultMessage = () => {
    return (
        <div className="navbar-nav ml-auto nav-flex-icons" >
            <div className="nav-item margin-left-right more-weight">
                Em breve abriremos as inscrições para o Hackthon desse ano,
                qualquer dúvida ou informação favor entrar em contato pelo email abaixo:
                <a className="nav-link open-sans centered-align"
                    style={{ cursor: 'pointer' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:contatohackfaesa@gmail.com">
                    &nbsp;contatohackfaesa@gmail.com&nbsp;
                </a>
            </div>
        </div>
    )
}

const ButtonsList = ({ buttons }) => {
    return <div className="navbar-nav ml-auto nav-flex-icons" >
        <div className="nav-item margin-left-right more-weight">
            <br />
            <div className="centered-align">
                {
                    buttons.map((item, index) => {
                        return (
                            <MDBBtn key={index} color='indigo' className='radius-50' href={item.link} target="_blank">
                                <b className="font-weight-bold clear-text-white">{item.text}</b>
                            </MDBBtn>
                        )
                    })
                }
            </div>
            <br />
        </div>
    </div>;
}

