import React, { Component } from 'react';
import { Space } from '../components/Space';

export default class SectionHeader extends Component {
    render() {
        var { title, content } = this.props;
        var animatedClass = "animated fadeInUp more-weight";

        return (
            <div className="row pt-5">
                <div className="col-md-8">
                    <h3 className={`${animatedClass} text-orange title-header`}>{title}</h3>
                    <br />
                    <p className={`${animatedClass} text-blue`}>{content}</p>
                </div>
                <Space />
                <hr />
            </div>
        )
    }
}
