
import React, { Component } from 'react';
import SectionHome from '../sections/SectionHome';
import { LogoHackFaesa } from '../components/Logo';
import HackFaesaDescription from '../components/HackFaesaDescription';
import Promoting from '../components/Promoting';
import { Pillars } from '../components/Pillars';

export default class HomePage extends Component {

    render() {
        const { data } = this.props;

        return <>
            <SectionHome id={1}>
                <LogoHackFaesa />
                <HackFaesaDescription content={data} />
            </SectionHome>
            <SectionHome id={2}>
                <Pillars pillars={data.pillars} />
            </SectionHome>
            <SectionHome id={3}>
                <Promoting promoting={data.promoting} numbers={data.numbers} />
            </SectionHome>
        </>;
    }

}

