import React from 'react';
import faesaLogo from '../assets/icons/hackfaesa_logo.png';
import hackathonLogo from '../assets/img/hackathon_logo.png';

export const LogoFaesa = () => {
    return (
        <div className="navbar-brand" style={{ color: '#616161' }}>
            <a href="https://www.faesa.br/" alt="Faesa" target="_blank" rel="noopener noreferrer">
                <img src={faesaLogo} className="model-navbar-brand" alt="" width="100" height="100" />
            </a>
        </div>
    )
}


export const LogoHackFaesa = () => {
    return <div className="col-md-6 padding-top-bottom">
        <img src={hackathonLogo} alt="" width="100%" height="100%"
            className="embed-responsive embed-responsive-16by9 model-main-brand"
        />
    </div>
}

