import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';

export class Fab extends Component {
    render() {
        return (
            <nav className="pt-1 pb-1 z-depth-3 fixed-bottom d-block d-md-block d-sm-block d-lg-none text-center">
                <div className="fab-wrapper">
                    {this.props.children}
                </div>
            </nav>
        )
    }
}

export class FabButton extends Component {
    render() {
        return (
            <div className="fab-button" onClick={() => this.props.action()}>
                <div><MDBIcon icon="plus" /></div>
            </div>
        )
    }
}

export class FabList extends Component {
    render() {
        const { fabClass, routes } = this.props
        return (
            <ul className={fabClass}>
                {
                    routes.map((item, index) => {
                        return (
                            <li className="fab-item" key={index}>
                                <NavLink to={item.url}>
                                    <div onClick={() => this.props.action(item.id)}>
                                        <MDBIcon icon={item.icon} className='iconColor' />
                                        <div className="text">{item.name}</div>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    }).reverse()
                }
            </ul>
        )
    }
}
