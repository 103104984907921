import React, { Component } from 'react';

export default class NavBar extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light deep-blue fixed-top" >
                <div className="container-fluid col-md-10 offset-md-1 offset-lg-1 offset-xl-1 pr-0 col-md-10 col-12">
                    {this.props.children}
                </div>
            </nav>
        )
    }
}
