import './index.css';
import './assets/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/mdb.css';
import './assets/css/mdb.min.css';
import './assets/css/style.css';
import './assets/css/style.min.css';
import './assets/scss/fab.scss';
import './assets/scss/bar.scss';
import './assets/scss/colors.scss';
import './assets/scss/noscript.scss';
import './assets/scss/pulse.scss';
import './assets/scss/view.scss';
import './assets/scss/bell.scss';
import './assets/scss/dropdown.scss';
import './assets/scss/notification.scss';

// MDB4 CSS/SCSS
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
