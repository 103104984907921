import React from 'react';

export const Pillars = ({ pillars }) => {
    return pillars.map((item, index) => {
        return (
            <div key={index} className="col-md-3 text-center p-5 animated fadeInUp clear-text-white">
                <i className={`${item.icon} align-text`} aria-hidden="true"></i>
                <br />
                <br />
                <h3 className="h3-responsive">{item.title}</h3>
                <p className="size-90 ">{item.content}</p>
            </div>
        )
    })
}