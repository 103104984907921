import React from 'react';
import { getData } from "../data/data";
import { getRoutes } from '../data/routes';
import { Route, Switch } from 'react-router-dom'
import { LogoFaesa } from './Logo';

import NavBar from './NavBar';
import { Footer } from './Footer';
import { Tabs } from './Tabs'
import ButtonSubscription from './ButtonSubscription';

import { MDBIcon } from 'mdbreact';

import EventPage from '../pages/EventPage';
import TeamPage from '../pages/TeamPage';
import NewsPage from '../pages/NewsPage';
import HomePage from '../pages/HomePage';

export default function Main() {

    const { data, error } = getData(`data.json`);

    const routes = getRoutes();

    if (error) return <ErrorPage />

    if (!data) return <LoadingPage />

    return (
        <>
            <NavBar>
                <LogoFaesa />
                <Tabs />
                <ButtonSubscription data={data.subscription} />
            </NavBar>
            <div className="margin-top-bar">
                <Switch>
                    <Route exact path={routes[0].url}><HomePage data={data.home} /></Route>
                    <Route exact path={routes[1].url}><EventPage data={data.event} /></Route>
                    <Route exact path={routes[2].url}><TeamPage data={data.team} /></Route>
                    <Route exact path={routes[3].url}><NewsPage data={data.news} /></Route>
                </Switch>
            </div>
            <Footer link={data.footer.link} />
        </>
    )
}

const LoadingPage = () => {
    return <div className='center-content'><MDBIcon spin={true} icon="spinner" /></div>
}

const ErrorPage = () => {
    return (
        <div className='center-error'>
            <div className="col-md-4 col-sm-6 mt-4">
                <div className="p-4 pt-4 deep-orange clear-text-white item-box">
                    <h5 className="more-weight">Opss..</h5>
                    <p>Algo deu errado do nosso lado e não conseguimos exibir as informações desta tela. Tente recarregar ou voltar mais tarde.</p>
                </div>
            </div>
        </div>
    )
}