import React, { Component } from 'react';
import SectionHeader from '../sections/SectionHeader'
import SectionAnimated from '../sections/SectionAnimated';
import SectionView from '../sections/SectionView';
import { TeamDescription, Organizers, Ambassadors } from "../components/Team";
import { Space } from '../components/Space';

export default class TeamPage extends Component {
    render() {
        const { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <Space />
                <div className="row">
                    <SectionAnimated>
                        <TeamDescription title={data.ambassador.title} content={data.ambassador.content} />
                    </SectionAnimated>
                    <SectionAnimated>
                        <Ambassadors list={data.ambassador.list} />
                    </SectionAnimated>
                    <SectionAnimated>
                        <TeamDescription title={data.organizer.title} content={data.organizer.content} />
                    </SectionAnimated>
                    <SectionAnimated>
                        <Organizers list={data.organizer.list} />
                    </SectionAnimated>
                </div>
                <Space />
            </SectionView>
        );
    }
}
