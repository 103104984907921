import React from 'react';
import { MDBBadge } from 'mdbreact';

export const TeamDescription = ({ title, content }) => {
    return (
        <div style={{ paddingLeft: '15px', paddingRight: '15px', }}>
            <h4 className="text-orange more-weight">{title}</h4>
            <p className="text-blue more-weight">{content}</p>
        </div>
    )
}

export const Ambassadors = ({ list }) => {
    return list.map((item, index) => {
        return (
            <div key={index} className="col-md-3 col-5 text-center mt-4 margin-left-right" >
                <img src={item.image} alt="" className="rounded-circle box-shad" width="100%" />
                <br /><br />
                <h5 className="h5-responsive">{item.name}</h5>
            </div>
        )
    });
}

export const Organizers = ({ list }) => {
    return list.map((user, index) => {
        return <div key={index} className="col-md-4 col-sm-12 mt-4">
            <div className="p-4 pt-4 deep-orange clear-text-white item-box">
                <div className="row centered-align">
                    <div className="col margin-top-bottom">
                        <OrganizerImage image={user.image} name={user.name} />
                        <h5 className="more-weight">{user.name}</h5>
                        <OrganizerDetails details={user.badges} />
                    </div>
                </div>
            </div>
        </div>
    })
}

const OrganizerDetails = ({ details }) => {
    var style = { padding: '5px', margin: '5px', borderRadius: '20px' };
    return details.map((item, index) => {
        return <MDBBadge key={index} color="light" style={style}>{item}</MDBBadge>
    });
}

const OrganizerImage = ({ image, name }) => {
    return (
        <div className="gradient-user">
            <img
                src={image} alt={name}
                className="rounded-circle box-shad"
                width="150" height="150"
            />
        </div>
    )
}
