import React, { Component } from 'react';

export class RulesWrapper extends Component {
    render() {
        return (
            <div className="row mt-5">
                <div className="col-md-12">
                    <table className="table table-hover animated fadeInUp" >
                        <tbody className="deep-orange">
                            {this.props.children}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export class RuleName extends Component {
    render() {
        return <td className="p-3 width-20"><div className="open-sans weight-600 ">{this.props.children}</div></td>;
    }
}

export class RuleContent extends Component {
    render() {
        return <td className="p-3 width-60"><div className="open-sans">{this.props.children}</div></td>
    }
}