import React, { Component } from 'react';

export default class SectionView extends Component {
    render() {
        return (
            <div className="container-fluid pt-2 mt-2">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="container-fluid">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
