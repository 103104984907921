import React, { Component } from 'react';

export default class HackFaesaDynamicText extends Component {
    componentDidMount = () => this.buildTextScramble();

    buildTextScramble = () => {
        var _createClass = function () {
            function defineProperties(target, props) {
                for (var i = 0; i < props.length; i++) {
                    var descriptor = props[i];
                    descriptor.enumerable = descriptor.enumerable || false;
                    descriptor.configurable = true;
                    if ("value" in descriptor) descriptor.writable = true;
                    Object.defineProperty(target, descriptor.key, descriptor);
                }
            }
            return function (Constructor, protoProps, staticProps) {
                if (protoProps) defineProperties(Constructor.prototype, protoProps);
                if (staticProps) defineProperties(Constructor, staticProps);
                return Constructor;
            };
        }();

        function _classCallCheck(instance, Constructor) {
            if (!(instance instanceof Constructor))
                throw new TypeError("Não é possivel chamar a classe da função");
        }

        var _text = function () {
            function TextScramble(el) {
                _classCallCheck(this, TextScramble);
                this.el = el;
                this.chars = '!<>-_\\/[]{}—=+*^?#________';
                this.update = this.update.bind(this);
            }
            _createClass(TextScramble, [{
                key: 'setText',
                value: function setText(newText) {
                    var _this = this;
                    var oldText = this.el.innerText;
                    var length = Math.max(oldText.length, newText.length),
                        promise = new Promise(function (resolve) {
                            return _this.resolve = resolve;
                        });
                    this.queue = [];
                    for (var i = 0; i < length; i++) {
                        var from = oldText[i] || '',
                            to = newText[i] || '',
                            start = Math.floor(Math.random() * 40),
                            end = start + Math.floor(Math.random() * 40);
                        this.queue.push({
                            from: from, to: to, start: start, end: end
                        });
                    }
                    cancelAnimationFrame(this.frameRequest);
                    this.frame = 0;
                    this.update();
                    return promise;
                }
            },
            {
                key: 'update',
                value: function update() {
                    var output = '',
                        complete = 0;
                    for (var i = 0, n = this.queue.length; i < n; i++) {
                        var _queue$i = this.queue[i],
                            from = _queue$i.from,
                            to = _queue$i.to,
                            start = _queue$i.start,
                            end = _queue$i.end,
                            char = _queue$i.char;
                        if (this.frame >= end) {
                            complete++;
                            output += to;
                        } else if (this.frame >= start) {
                            if (!char || Math.random() < 0.28) {
                                char = this.randomChar();
                                this.queue[i].char = char;
                            }
                            output += '<span style="color: #1C3864;">' + char + '</span>';
                        } else {
                            output += from;
                        }
                    }
                    this.el.innerHTML = output;
                    if (complete === this.queue.length) {
                        this.resolve();
                    } else {
                        this.frameRequest = requestAnimationFrame(this.update);
                        this.frame++;
                    }
                }
            },
            {
                key: 'randomChar',
                value: function randomChar() {
                    return this.chars[Math.floor(Math.random() * this.chars.length)];
                }
            }]);
            return TextScramble;
        }();

        var f = ['HACK FAESA', 'THINK IT', 'CODE IT', 'SOLVE IT'];
        var el = document.getElementsByClassName('dynamic-text')[0];
        var fx = new _text(el)
        var counter = 0;

        var next = function next() {
            var text = fx.setText(f[counter]);
            text.then(function () { setTimeout(next, 1200); });
            counter = (counter + 1) % f.length;
        }; next();
    }

    render() {
        return (
            <>
                <h1 className="display-5 animated fadeInUp font-400">
                    <div className="dynamic-text"></div>
                </h1>
                <br />
            </>
        )
    }
}