import React, { Component } from 'react';
import { MDBBtn } from "mdbreact";

export default class SectionTabs extends Component {

    render() {
        const { state, tabsList } = this.props;
        return (
            <div className="container-fluid z-depth-1 pt-2 mt-2" >
                <div className="row p-2 p-0">
                    <div className="col-md-10 offset-md-1 pr-0 pl-1">
                        <div className="overflow-options md-tabs mx-0 mb-0 mt-1 mb-1 p-0" >
                            {
                                tabsList.map((name, index) => {
                                    var tab = state.tabs[index];
                                    var colorName = tab ? 'blue' : 'deep-orange';
                                    var btnClassName = `radius-50 ${tab ? '' : 'opacity-less'}`;
                                    return <MDBBtn
                                        key={index}
                                        color={colorName}
                                        className={btnClassName}
                                        onClick={() => this.props.action(index)}
                                        outline>
                                        {name}
                                    </MDBBtn>;
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}