import React from 'react';
import { MDBContainer, MDBFooter } from "mdbreact";
import movie from '../assets/img/movie.png';

export const Footer = ({ link }) => {
    var style = { marginLeft: '10px', marginRight: '10px' };
    return (
        <MDBFooter className="deep-blue font-small">
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    <a style={style} href={link} alt="Movie" target="_blank" rel="noopener noreferrer">
                        <img src={movie} className="model-navbar-brand" alt="" width="100" height="100" />
                    </a>
                </MDBContainer>
            </div>
        </MDBFooter>
    )
}