
import React, { Component } from 'react';

export default class SectionAnimated extends Component {
    render() {
        return (
            <div className="col-md-12">
                <div className="row animated fadeInUp">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
