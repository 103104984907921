import React, { Component } from 'react';
import SectionWinners from '../sections/SectionWinners'
import SectionVideos from '../sections/SectionVideos';
import SectionFeedbacks from '../sections/SectionFeedbacks';
import SectionTabs from "../sections/SectionTabs";

export default class NewsPage extends Component {

    constructor() {
        super()
        this.state = { tabs: [true, false, false] };
    }


    switchTab(index) {
        this.setState((state) => {
            state.tabs = [false, false, false, false]
            const newTabs = [...state.tabs];
            newTabs[index] = !newTabs[index];
            return { tabs: newTabs };
        });
    }


    render() {

        const { tabs } = this.state;
        const { data } = this.props;
        const tabsList = [
            'Vencedores', 'Vídeos', 'Feedbacks'
        ];
        return (
            <>
                <SectionTabs
                    state={this.state}
                    tabsList={tabsList}
                    action={(index) => this.switchTab(index)}
                />
                {
                    (tabs[0] && <SectionWinners data={data.sectionWinners} />) ||
                    (tabs[1] && <SectionVideos data={data.sectionVideos} />) ||
                    (tabs[2] && <SectionFeedbacks data={data.sectionFeedbacks} />)
                }
            </>
        )
    }
}