import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { getRoutes } from './data/routes';
import { Fab, FabButton, FabList } from './components/Fab';
import './index';
import Main from "./components/Main";

export default class App extends Component {
  state = {
    tabs: [true, false, false, false],
    isMobile: false,
  };

  switchMobileVibisility() {
    this.setState((state) => {
      return { isMobile: !state.isMobile };
    })
  }

  setNewMobileNavigation(index) {
    this.setState((state) => {
      state.tabs = [false, false, false, false]
      const newTabs = [...state.tabs];
      newTabs[index] = !newTabs[index];
      return { isMobile: false, tabs: newTabs };
    });
  }

  render() {
    const routes = getRoutes();
    var { isMobile } = this.state;

    return (
      <BrowserRouter>
        <div className={isMobile ? "views withFab" : "views"}>
          <Main />
        </div>
        <Fab>
          <FabButton action={() => this.switchMobileVibisility()} />
          <FabList
            fabClass={isMobile ? 'fab-item-list activated' : 'fab-item-list disabled'}
            routes={routes}
            action={(index) => this.setNewMobileNavigation(index)}
          />
        </Fab>
      </BrowserRouter>
    )
  }
}



