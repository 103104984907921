import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import SectionView from './SectionView';


export default class SectionAwards extends Component {
    render() {
        const { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <div className="row">
                    <TrophyList trophys={data.trophys} />
                </div>
            </SectionView>
        )
    }
}


function getTrophyType(model) {
    if (model === "gold") return TrophyType.gold()
    else if (model === "silver") return TrophyType.silver()
    else if (model === "bronze") return TrophyType.bronze()
    else return TrophyType.generic()
}

const TrophyList = ({ trophys }) => {
    return trophys.map((item, index) => {
        var tropyType = getTrophyType(item.model)
        return <Trophy
            key={index}
            title={item.title}
            content={item.content}
            tropy={tropyType}
        />
    })
}

const Trophy = ({ tropy, title, content }) => {
    return (
        <div className="col-md-4 text-center p-5 animated fadeInUp text-blue">
            {tropy}
            <h3 className="h3-responsive more-weight">{title}</h3>
            <p className="more-weight" style={{ fontSize: '90%' }}>{content}</p>
        </div>
    )
}

const TrophyType = {
    gold: () => GoldTrophy(),
    silver: () => SilverTrophy(),
    bronze: () => BronzeTrophy(),
    generic: () => GenericTrophy(),
}
const GenericTrophy = () => {
    return <TrophyIcon color='#ff835d' />;
}

const SilverTrophy = () => {
    return <>
        <MoneyIcon align='left' />&nbsp;
        <TrophyIcon color='#80807e' />&nbsp;
        <MoneyIcon align='right' /><br />&nbsp;
        <MoneyIcon align='left' />&nbsp;
        <MoneyIcon align='right' />&nbsp;
    </>
}

const BronzeTrophy = () => {
    return <>
        <TrophyIcon color='#DE7744' /><br />&nbsp;
        <MoneyIcon align='left' />&nbsp;
        <MoneyIcon align='center' />&nbsp;
        <MoneyIcon align='right' />&nbsp;
    </>
}

const GoldTrophy = () => {
    return <>
        <MoneyIcon align='left' />&nbsp;
        <TrophyIcon color='#CD7F32' />&nbsp;
        <MoneyIcon align='right' /><br />&nbsp;
        <MoneyIcon align='left' />&nbsp;
        <MoneyIcon align='center' />&nbsp;
        <MoneyIcon align='right' />&nbsp;
    </>
}

const TrophyIcon = ({ color }) => {
    var className = 'fas fa-trophy fa-3x';
    var style = { color: color, textAlign: "center" };
    return <i className={className} style={style} aria-hidden="true"></i>
}

const MoneyIcon = ({ align }) => {
    var className = 'fas fa-money-bill-wave fa-1x accept-color';
    var style = { textAlign: align };
    return <i className={className} style={style} aria-hidden="true"></i>
}

