import React, { Component } from "react";
import { MDBBtn } from "mdbreact";
import SectionAnimated from "../sections/SectionAnimated";
import { Space } from "./Space";

export class Winners extends Component {

    render() {
        const { winners } = this.props;

        return <div className="row">
            {
                winners.map((winner, index) => {
                    return (
                        <div key={index}>
                            <WinnersEdition edition={winner.edition} />
                            <SectionAnimated>
                                <Winner image={winner.firstGroupImage} name="1°Lugar" />
                                <Winner image={winner.secondGroupImage} name="2°Lugar" />
                                <Winner image={winner.thirdGroupImage} name="3°Lugar" />
                            </SectionAnimated>
                            <SectionAnimated>
                                <WinnersPhotosButton link={winner.galleryLink} edition={winner.edition} />
                            </SectionAnimated>
                            <Space />
                        </div>
                    )
                })
            }
        </div>
    }
}

const Winner = ({ image, name }) => {
    return (
        <div className="col-md-4 col-6 text-center mt-4 margin-left-right" >
            <img src={image} className="rounded box-shad load-img" width="100%" height="80%" alt="" />
            <br />
            <MDBBtn className="margin-left-right radius-50" color='deep-orange' outline disabled>{name}</MDBBtn>
        </div>
    )
}
const WinnersEdition = ({ edition }) => {
    return <div className="col-md-12">
        <h4 className="text-orange animated fadeInUp">
            {`Ganhadores da ${edition}° Edição`}
        </h4>
    </div>
}

const WinnersPhotosButton = ({ link, edition }) => {
    return <div className="col-md-4 col-12 text-center mt-4 margin-left-right">
        <MDBBtn className="margin-left-right radius-50"
            color='deep-orange'
            target="_blank" rel="noopener noreferrer"
            href={link}>
            {`Fotos da ${edition}° Edição`}
        </MDBBtn>
    </div>
}