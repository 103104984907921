import React, { Component } from 'react';
import SectionHeader from './SectionHeader';

import SectionView from "./SectionView";
import { Winners } from '../components/Winners';

export default class SectionWinners extends Component {

    render() {
        const { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <Winners winners={data.winners} />
            </SectionView>
        );
    }
}
