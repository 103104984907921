
export function getRoutes() {
    return [
        {
            id: 0,
            url: '/',
            name: 'Home',
            icon: 'home',
        },
        {
            id: 1,
            url: '/event',
            name: 'Evento',
            icon: 'calendar-alt',
        },
        {
            id: 2,
            url: '/team',
            name: 'Equipe',
            icon: 'users',
        },
        {
            id: 3,
            url: '/news',
            name: 'News',
            icon: 'paperclip',
        },
    ];
}