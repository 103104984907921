import React, { Component } from "react";
import SectionRules from '../sections/SectionRules'
import SectionBootcamp from '../sections/SectionBootcamp'
import SectionAwards from '../sections/SectionAwards';
import SectionDoubts from '../sections/SectionDoubts';
import SectionSponsors from '../sections/SectionSponsors';
import SectionTabs from "../sections/SectionTabs"

export default class EventPage extends Component {

    constructor() {
        super()
        this.state = { tabs: [true, false, false, false, false] };
    }

    switchTab(index) {
        this.setState((state) => {
            state.tabs = [false, false, false, false, false]
            const newTabs = [...state.tabs];
            newTabs[index] = !newTabs[index];
            return { tabs: newTabs };
        });
    }

    render() {
        const { tabs } = this.state;
        const { data } = this.props;
        const tabsList = [
            'Hackathon', 'Bootcamp', 'Dúvidas', 'Premiações', 'Patrocinadores'
        ];

        return (
            <>
                <SectionTabs
                    state={this.state}
                    tabsList={tabsList}
                    action={(index) => this.switchTab(index)}
                />
                {
                    (tabs[0] && <SectionRules data={data.sectionRules} />) ||
                    (tabs[1] && <SectionBootcamp data={data.sectionBootcamp} />) ||
                    (tabs[2] && <SectionDoubts data={data.sectionDoubts} />) ||
                    (tabs[3] && <SectionAwards data={data.sectionAwards} />) ||
                    (tabs[4] && <SectionSponsors data={data.sectionSponsors} />)
                }
            </>
        );
    }
}
