import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import SectionView from './SectionView';
import SectionAnimated from "./SectionAnimated";
import { Space } from '../components/Space';

export default class SectionDoubts extends Component {
    render() {
        var { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <SectionAnimated>
                    <Doubts doubts={data.doubts} />
                </SectionAnimated>
                <Space />
            </SectionView>
        )
    }
}

const Doubts = ({ doubts }) => {
    return doubts.map((item, index) => {
        return <Doubt key={index} title={item.title} content={item.content} />
    });
}

const Doubt = ({ title, content }) => {
    return (
        <div className="col-md-4 col-sm-6 mt-4">
            <div className="p-4 pt-4 deep-orange clear-text-white item-box">
                <h5 className="more-weight">{title}</h5>
                <p>{content}</p>
            </div>
        </div>
    )
}
