import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import SectionView from './SectionView';
import SectionAnimated from "./SectionAnimated";
import { Space } from '../components/Space';

export default class SectionBootcamp extends Component {
    render() {
        var { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <SectionAnimated>
                    <BootcampCardsList bootcamps={data.bootcamps} />
                </SectionAnimated>
                <Space />
            </SectionView>
        )
    }
}

const BootcampCardsList = ({ bootcamps }) => {
    return bootcamps.map((item, index) => <BootcampCard key={index} content={item} />)
}

const BootcampCard = ({ content }) => {
    return (
        <div className="col-md-6 col-sm-6 mt-4">
            <div className="p-4 pt-4 deep-orange clear-text-white item-box">
                <h3 className="more-weight">{content.title}</h3>
                {
                    content.isReady
                        ? <p>
                            <BootItem text="Dia" data={content.date} />
                            <BootItem text="Local" data={content.local} />
                            <BootItem text="Palestrante" data={content.speaker} />
                            <i>{content.speakerJob}</i>
                        </p>
                        : <p>{content.loadingBootcamps}</p>
                }

            </div>
        </div>
    )

}

const BootItem = ({ text, data }) => {
    return <><b className="font-weight-bold">{text}</b>: {data}<br /></>
}