import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import SectionView from "./SectionView";
import SponsorsEdition from '../components/SponsorsEdition';

export default class SectionSponsors extends Component {
    render() {
        const { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <SponsorsEditionList list={data.list} />
            </SectionView>
        )
    }
}

const SponsorsEditionList = ({ list }) => {
    return list.map((edition, index) => <SponsorsEdition key={index} edition={edition} />);
}
