import useSWR from "swr";
import axios from "axios";

const api = axios.create({
    baseURL: "https://raw.githubusercontent.com/HackFaesa/test-data/main/",
});


export function getData(url) {
    const { data, error, mutate } = useSWR(
        url,
        async (url) => {
            const response = await api.get(url);
            var data = JSON.parse(JSON.stringify(response.data));
            return data;
        },
        {}
    );

    return { data, error, mutate };
}