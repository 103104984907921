import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import SectionView from "./SectionView";
import SectionAnimated from './SectionAnimated';

export default class SectionFeedbacks extends Component {
    render() {
        const { data } = this.props;
        return (
            <SectionView>
                <SectionHeader title={data.title} content={data.content} />
                <SectionAnimated>
                    <FeedbacksList feedbacks={data.feedbacks} />
                </SectionAnimated>
            </SectionView>
        )
    }
}

const FeedbacksList = ({ feedbacks }) => {
    var list = feedbacks.map((item, index) => <Feedback key={index} item={item} />);
    return <table className="table table-hover text-blue"><tbody>{list}</tbody></table >

}

const Feedback = ({ item }) => {
    return (
        <tr>
            <td className="p-3">
                <p className="open-sans"><i>"{item}"</i></p>
            </td>
        </tr>
    )
}
